exports.components = {
  "component---src-templates-activation-tsx": () => import("./../../../src/.templates/activation.tsx" /* webpackChunkName: "component---src-templates-activation-tsx" */),
  "component---src-templates-activations-list-tsx": () => import("./../../../src/.templates/activations-list.tsx" /* webpackChunkName: "component---src-templates-activations-list-tsx" */),
  "component---src-templates-add-activation-tsx": () => import("./../../../src/.templates/add-activation.tsx" /* webpackChunkName: "component---src-templates-add-activation-tsx" */),
  "component---src-templates-cookies-policy-tsx": () => import("./../../../src/.templates/cookies-policy.tsx" /* webpackChunkName: "component---src-templates-cookies-policy-tsx" */),
  "component---src-templates-custom-tsx": () => import("./../../../src/.templates/custom.tsx" /* webpackChunkName: "component---src-templates-custom-tsx" */),
  "component---src-templates-dashboard-tsx": () => import("./../../../src/.templates/dashboard.tsx" /* webpackChunkName: "component---src-templates-dashboard-tsx" */),
  "component---src-templates-faq-tsx": () => import("./../../../src/.templates/faq.tsx" /* webpackChunkName: "component---src-templates-faq-tsx" */),
  "component---src-templates-home-tsx": () => import("./../../../src/.templates/home.tsx" /* webpackChunkName: "component---src-templates-home-tsx" */),
  "component---src-templates-join-tsx": () => import("./../../../src/.templates/join.tsx" /* webpackChunkName: "component---src-templates-join-tsx" */),
  "component---src-templates-login-tsx": () => import("./../../../src/.templates/login.tsx" /* webpackChunkName: "component---src-templates-login-tsx" */),
  "component---src-templates-privacy-policy-tsx": () => import("./../../../src/.templates/privacy-policy.tsx" /* webpackChunkName: "component---src-templates-privacy-policy-tsx" */),
  "component---src-templates-register-tsx": () => import("./../../../src/.templates/register.tsx" /* webpackChunkName: "component---src-templates-register-tsx" */),
  "component---src-templates-thank-you-tsx": () => import("./../../../src/.templates/thank-you.tsx" /* webpackChunkName: "component---src-templates-thank-you-tsx" */)
}

