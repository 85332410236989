import { IFileBase64 } from './file-base-64.model';
import { IMedia } from '@alterpage/gatsby-source-alterpress';

export type TActivationStatus =
    | 'new'
    | 'waiting-for-approval'
    | 'approval-copy'
    | 'approved-waiting-for-copy'
    | 'approved-with-all-documents'
    | 'request-for-improvement'
    | 'rejected';

export const activationStatuses = {
    new: 'new',
    waitingForApproval: 'waiting-for-approval',
    approvedWaiting: 'approved-waiting-for-copy',
    approved: 'approved-with-all-documents',
    improvement: 'request-for-improvement',
    rejected: 'rejected',
};

export interface IActivation extends IActivationMutation {
    withSeeding: boolean;
    dateFrom: string;
    dateTo: string;
    createdAt: number;
    media: IMedia[];
    templateDocument: IMedia;
    templateName: string;
    availableActions: {
        edit: boolean;
        delete: boolean;
        'manage-attachments': boolean;
        'confirm-documents': boolean;
        'download-template': boolean;
    };
    isVisible?: boolean;
    slot?: number;
    prices?: IActivationPrices;
}

export interface IActivationMutation
    extends IActivationFormStepOneValues,
        IActivationFormStepTwoValues,
        IActivationFormStepThreeValues {
    activationId?: number | null;
    step: number | null;
    chiefReview: string;
    submitForVerification: boolean;
    status?: TActivationStatus;
    mode?: 'new' | 'edit';
}

export interface IActivationFormStepOneValues {
    divisionIds: number[];
    brandIds: number[];
    ownerEmail: string;
    type: 'seed' | 'no-seed' | '';
    templateId: number | '';
    tasks: IActivationTaskData[];
    focus: string;
    clubIds: string[];
    customGroup: string;
    name: string;
    displayName: string;
}

export interface IActivationFormStepTwoValues {
    packagesCount: number | '';
    productsCount: number | '';
    packageId: number | '';
    cost: number | '';
    totalCost: number | '';
    products: IActivationProduct[];
    rewards: IActivationReward[];
    isTaxableReward: boolean | '';
    includedProducts: string;
}

export interface IActivationFormStepThreeValues {
    comment: string;
}

export interface IActivationProduct {
    name: string;
    sizeId: number;
    code: string;
    valueGross: number;
    capacity: string;
    image: IFileBase64;
}

export interface IActivationReward {
    name: string;
    valueGross: number;
    taxId: number;
    tax: number;
    image: IFileBase64;
}

export interface IActivationBrand {
    brandId: number;
    createdAt: number;
    name: string;
}

export interface IActivationClub {
    clubId: number;
    createdAt: number;
    name: string;
}

export interface IActivationDivision {
    divisionId: number;
    createdAt: number;
    name: string;
}

export interface IActivationPackage {
    packageId: number;
    name: string;
    price: number;
    createdAt: number;
}

export interface IActivationProductSize {
    sizeId: number;
    createdAt: number;
    name: string;
    shortDescription: string;
}

export interface IActivationTemplate {
    templateId: number;
    createdAt: number;
    name: string;
    warmupDuration: number;
    withSeeding: boolean;
    tasks: IActivationTask[];
}

export interface IActivationTask {
    isHardTask: boolean;
    isQuestionnaire: boolean;
    minDuration: number;
    name: string;
    taskId: number;
    verificationDuration: number;
    verificationProlongDuration: number;
}

export interface IActivationTaskData {
    taskId: number;
    days: number;
    dateFrom: string;
}

export interface IOtherActivations {
    taskId: number;
    otherActivations: number;
}

export interface IActivationTax {
    taxId: number;
    systemName: string;
}

export interface IActivationPrices {
    allActivationRewardsInDiamonds: string;
    allActivationRewardsInPlnGross: string;
    allActivationRewardsInPlnNet: string;
    aveMainReward: string;
    aveSecondaryReward: string;
    logisticsCostForAllPackagesGross: string;
    logisticsCostForAllPackagesNet: string;
    numberOfMainRewards: string;
    numberOfSecondaryRewards: string;
    productMarketValueInOnePackage: string;
    productsTaxCostForAllPackages: string;
    questionnaireRewardInDiamonds: string;
    questionnaireRewardInTotalNet: string;
    questionnaireRewardInTotalNetDiamonds: string;
    quizRewardInDiamonds: string;
    quizRewardInTotalNet: string;
    quizRewardInTotalNetDiamonds: string;
    rewardMarketValueGross: string;
    rewardMarketValueNet: string;
    rewardTax: string;
    suggestedSeedingCoefficient: string;
    suggestedSeedingCoefficientHintData: { from: string; value: string }[];
    sumGross: string;
    sumNet: string;
    taskWithPublicationMainRewardInTotalNet: string;
    taskWithPublicationMainRewardInTotalNetDiamonds: string;
    taskWithPublicationMainRewardNet: string;
    taskWithPublicationMainRewardNetDiamonds: string;
    taskWithPublicationSecondaryRewardInTotalNet: string;
    taskWithPublicationSecondaryRewardInTotalNetDiamonds: string;
    taskWithPublicationSecondaryRewardNet: string;
    taskWithPublicationSecondaryRewardNetDiamonds: string;
    totalProductMarketValueInAllPackages: string;
    totalProductTaxInOnePackage: string;
    totalRewardCostGross: string;
    totalRewardCostNet: string;
    totalRewardMarketValueGross: string;
    totalRewardMarketValueNet: string;
    totalSeedingCostForActivationGross: string;
    totalSeedingCostForActivationNet: string;
    totalRewardTax: string;
    logisticsInfo: string;
    totalInfo: string;
}

export interface IActivationFormData {
    activationsData: {
        brands: IActivationBrand[];
        clubs: IActivationClub[];
        divisions: IActivationDivision[];
        packages: IActivationPackage[];
        productSizes: IActivationProductSize[];
        templates: IActivationTemplate[];
        taxes: IActivationTax[];
    };
}
